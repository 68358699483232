*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body{
  width: 100%;
  height: 100%;
  font-family: Nunito;
}

.d_center{
  display: flex;
  justify-content: center;
  align-items: center;
}


/* *------------------------------------------------------Navbar style */


.Navbar_main{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4.8vmax;
  border-top: 5px solid #29CA8E;
  padding: 0px 110px ;
  position: fixed;
  z-index: 9;
  background-color: white;
}

.Navbar_nav_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: inherit;

}

.Navlink{
  min-height: inherit;
  padding: 0px 20px;
  background-color: white;
  color: #757575;
  font-size: 14px;
}

.Phone_span{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #29CA8E;
  border-radius: 50%;
  margin:0px 5px 0px 0px;
  line-height:28px;
  padding: 0px 9px;

}

.Phone_span>i{
  font-size: 12px;
  color: white;
  margin-bottom: 2px;
}

.Navbar_nav_lastdiv{
  min-height: inherit;
  padding: 15px 12px;
  display: flex;
  background-color: white;
  color: #757575;
  justify-content: space-between;
  align-items: center;
}

.Navbar_nav_lastdiv>span{
  font-size: 14px;
}

.Navbar_nav_firstdiv{
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.Navbar_nav_firstdiv>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* *------------------------------------------------------Home style */


.Home_main_upper_div_col1{
  background-image: url('https://themewagon.github.io/known/images/slider-image3.jpg');
  background-size: cover;
  background-position: center;
  min-height: 700px;
  position: relative;
}

.Home_text_div_col1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-height: 15vmax;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home_text_div_col1>h1{
  text-decoration: none solid rgb(51, 51, 51) ;
  font-size: 42px;
  color: white;
}

.Home_text_div_col1>h3{
  text-decoration: none solid rgb(51, 51, 51) ;
  font-size: 14px;
  color: white;
}

.Home_text_div_col1>button{
  width: 12%;
  font-size: 14px;
  padding: 10px;
}

.Home_main_upper_div_col2{
  background-image: url('https://themewagon.github.io/known/images/slider-image1.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 700px;
  position: relative;
}

.Home_text_div_col2{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-height: 15vmax;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.Home_text_div_col2>h1{
  text-decoration: none solid rgb(51, 51, 51) ;
  font-size: 42px;
  color: white;
}

.Home_text_div_col2>h3{
  text-decoration: none solid rgb(51, 51, 51) ;
  font-size: 14px;
  color: white;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 200;
}

.Home_text_div_col2>button{
  width: 14%;
  font-size: 14px;
  padding: 10px;
}

.Home_main_upper_div_col3{
  background-image: url('https://themewagon.github.io/known/images/slider-image2.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 700px;
  position: relative;
}

.Home_text_div_col3{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-height: 15vmax;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.Home_text_div_col3>h1{
  text-decoration: none solid rgb(51, 51, 51) ;
  font-size: 42px;
  color: white;
}

.Home_text_div_col3>h3{
  text-decoration: none solid rgb(51, 51, 51) ;
  font-size: 14px;
  color: white;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 200;
}

.Home_text_div_col3>button{
  width: 14%;
  font-size: 14px;
  padding: 10px;
}


/* ----------------------------------------------------------  courses */



.Home_main_lower_div {
  padding: 100px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Home_main_lower_box {
  border: 1px solid #F0F0F0;
  text-align: center;
  padding: 20px 15px;
  width: 370px !important;
  margin: 20px; /* Add some margin between boxes */
  /* box-sizing: border-box; Include padding in the width */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Home_main_lower_box:hover {
  transform: scale(1.05); /* Add a slight scale effect on hover */
}

.Home_main_lower_box > h3 {
  color: #454545;
  font-size: 21px;
  margin-top: 20px;
}

.Home_main_lower_box_circle {
  width: 60px;
  height: 60px;
}

.Home_main_lower_box_circle>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Home_main_lower_box > p {
  color: #757575;
  font-size: 14px;
  width: 100%;
  margin-top: 15px; /* Adjust spacing between heading and paragraph */
}
/* ------------------------------------------------------------------  About style */



.About_main_div{
  min-height: 650px;
  padding: 50px 0px 50px 0px ;
  display: flex;
  justify-content: center;
  align-items: flex-start;

}

.About_main_column_div{
  min-height: 450px;
  margin: 0px -15px 0px -15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.About_main_column_left_div{
  width:585px;
  height: 444px;
  padding: 0px 15px 0px 15px;
  /* background-color:red; */
}

.About_main_column_left_div>h2{
  font-size: 28px;
  margin: 20px 0 10px 0;
  padding: 0px 0px 10px 0px;
  display: block;
  color: #353535;
  font-weight: 700;
  font-family: Muli,sans-serif;
  /* background-color:red; */
}

.About_main_column_left_div_col{
  height: 108px;
  display: flex;
}

.About_main_column_left_div_col>span{
 width: 90px;
 height: 80px;
 margin:0 0 0 -15px;
 padding: 15px 20px 15px 20px;

}

.About_main_column_left_div_col>span>i{
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  background-color: #29CA8E;
  height: 50px;
  width: 50px;
  display: inline-block;
  color: white;
  border-radius: 50%;
}

.About_main_column_left_div_col_right>h3{
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  margin: 0px 0 0 0 ;
  color: #454545;
}


.About_main_column_left_div_col_right>p{
  font-size: 14px;
  font-weight: 200;
  line-height: 24px;
  margin: 0px 0px 0px 0px ;
  color: #757575;
}


.About_main_column_right_div{
  width: 390px;
  min-height:inherit;
  padding: 0px 10px 0px 10px ;
  /* background-color: #29CA8E; */
}

.About_main_column_right_form_div{
width: 400px;
height: 400px;
border-radius: 50%;
padding: 84px 84px 84px 84px;
/* background-color: red; */
background-color: #29CA8E;
}


.About_main_column_right_form_div>h2{
  font-size: 28px;
  padding: 0 0 0px 0;
  text-align: center;
  color: white;
}

.About_main_column_right_form_div>form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.input_style{
  height: 45px;
  margin: 0px 0px 6px 0px ;
  padding: 6px 12px 6px 12px;
  border: none;
  outline: none;
  background-color: #29CA8E;
  border-bottom: 1px solid #F0F0F0;
  color: #F0F0F0 ;
}

/* ---------------------------------------------------------------  Our teacher style */


.ourteacher_main_page_div{
  min-height: 670px;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(249,249,249);
}

.ourteacher_main_upper_div{
  padding: 18px;
  /* background-color: red; */
  text-align: center;
  line-height: 8px;
  margin-top: 20px;
}

.ourteacher_main_upper_div>h2{
font-size: 28px;
color: #353535;
}

.ourteacher_main_upper_div>small{
  font-size: 18px;
  color: #777777;
}

.ourteacher_main_lower_div{
  height: 425px;
  /* background-color: red; */
  
  display: flex;
}

.ourteacher_main_lower_box{
  width:292px;
  height: inherit;
  margin: auto;
  border-radius: 10px;
}

.ourteacher_main_lower_div_img{
  width: 262px;
  height: 276px;
  object-fit: cover;
}

.ourteacher_main_lower_box{
  width: 262px !important;
  overflow: hidden;
}

.ourteacher_main_lower_box_div{
  width: 262px;
  min-height: 88px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 5px;
  padding: 20px;
  border-radius: 10px;
}

.ourteacher_main_lower_box_Link_h3{
  font-size: 21px;
  font-weight: 700;
  color: #454545;
}


.ourteacher_main_lower_box_Link_h3:hover{
  color: #258754;
}
  
.ourteacher_main_lower_box_div>h3{
font-size: 21px;
font-weight: 700;
color: #454545;
}

.ourteacher_main_lower_box_lower_div{
  height: 45px;
  width: 262px;
  border-top: 1px solid black;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.hoverlink{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 15px;
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #252020;
  margin-left: 18px;
}


/* ----------------------------------------------------- Reviews style */


.reviews_main_page_div{
  min-height: 670px;
  padding: 100px 50px 100px 50px ;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #F9F9F9;
}

.reviews_main_upper_div{
  padding: 18px;
  /* background-color: red; */
  text-align: center;
  line-height: 8px;
  margin-top: 20px;
}

.reviews_main_upper_div>h2{
font-size: 28px;
color: #353535;
}

.reviews_main_upper_div>small{
  font-size: 18px;
  color: #777777;
}

.reviews_main_lower_div{
  height: 425px;
  /* ackground-color: red; */
  display: flex;
}

.reviews_main_lower_box{
  width: 350px;
  background-color: #FFFFFF;
  margin: auto;
  padding: 56px 42px 56px 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.reviews_main_lower_box_header{
  width: 100%;
  display: flex;
}

.reviews_main_lower_box_header_circle{
  width: 60px;
  height:60px;
  border-radius: 50%;
  overflow: hidden;
}

.reviews_main_lower_box_header_circle>img{
  width: 100%;
  height:100%;
  object-fit: cover;
  
}

.reviews_main_lower_box_header_heading{
   /* background-color: black; */
  padding: 7px 20px ;
  line-height: 0px;
}

.reviews_main_lower_box_header_heading>h4{
font-size: 18px;
color: #353535;

}


.reviews_main_lower_box_header_heading>span{
  font-size: 14px;
  color: #757575;
}


.reviews_main_lower_box>p{
  font-size: 16px;
  margin-top: 20px;
  text-align: start;
  color: #757575;
  
}


.reviews_main_lower_box_footer{
  display: flex;
    align-items: center;
  }

  .reviews_main_lower_box_footer>i{
    margin-right: 10px;
    color: #3F51B5;
    font-size: 14px;
  }
  
  /* --------------------------------------------------  contact us div */

 .contact_main_page_div{
  height: 662px;
  background-color: rgb(255,255,255);
  padding: 100px 0px 100px 0px;
  display: flex;
 } 

 .contact_main_page_left_div{
  width: 50%;
  height: initial;
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 120px;
 } 

 .contact_main_page_left_div_header{
  line-height: 10px;
}


 .contact_main_page_left_div_header>h2{
  font-size: 28px;
  color: #252020;
 }
 
 .contact_main_page_left_div_header>small{
  font-size: 14px;
  color: #252020;
 }

 .contact_main_page_left_div_input{
  width: 100%;
  padding: 12px;
  background-color: rgb(255,255,255);
  outline: none;
  border: none;
  border-radius: 6px;
  border-bottom: 1px solid #252020;
  color: #757575;
  margin-top: 20px;
 }

 .Navlink.active {
  background-color: #3BCA8E;
  color: white;
}


 .contact_main_page_left_div_input-textaraa{
  width: 100%;
  padding: 12px;
  height: 130px;
  background-color: rgb(255,255,255)0;
  outline: none;
  border: none;
  border-radius: 6px;
  color: #757575;
  text-align: start;
  border-bottom: 1px solid #252020;
 }

 .contact_main_page_left_div_submit_btn{
  padding: 10px;
  width: 30%;
  color: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  outline: none;
  border: none;
  margin-top: 20px;
 }

 .contact_main_page_right_div{
  width: 50%;
  height: initial;
  padding: 10px 70px;
}

.contact_main_page_right_img_div{
  background-color: red;
  width: initial;
  height: initial;
  overflow: hidden;
  
 }
 .contact_main_page_right_img_div>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
 }


 /* ---------------------------------------------------------------------------footer page style */

.footer_main_div{
  background-color: #353535;
  padding: 100px 50px 100px 50px;
}

.footer_main_box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_main_box_col_1{
  padding: 20px;
  /* background-color: red; */
}

.footer_main_box_col_1>h2{
  font-size: 20px;
  color: #FFFFFF;

}

.footer_main_box_col_1>p{
  color: #909090;
  font-size: 12px;
  text-align: start;
  width: 70%;
  /* line-height: 7px; */
}

.footer_main_box_col_1_links_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.footer_main_box_col_1_link {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;

}

.footer_main_box_col_1_lower_links_div{
  width: 80%;
  /* background-color: red; */
}

.footer_main_box_col_1_lower_links{
  text-decoration: none;
  font-size: 14px;
  color: #909090;
  margin-right: 15px;
}


.footer_main_box_col_2{
  padding: 0px 66px;
  /* background-color: red; */
}

.footer_main_box_col_2>h2{
  font-size: 20px;
  color: #FFFFFF;

}

.footer_main_box_col_2>p{
  color: #909090;
  font-size: 12px;
  text-align: start;
  width: 70%;
  /* line-height: 7px; */
}

.footer_main_box_col_2_links_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.footer_main_box_col_2_lower_links_div{
  width: 80%;
  /* background-color: red; */
}

.footer_main_box_col_2_lower_links{
  text-decoration: none;
  font-size: 14px;
  color: #909090;
  margin-right: 15px;
}


.footer_main_box_col_3{
  padding: 40px 0px;
  /* background-color: red; */
}

.footer_main_box_col_3>h2{
  font-size: 20px;
  color: #FFFFFF;

}



.footer_main_box_col_3_input{
  width: 100%;
  background-color: #353535;
  border: none;
  outline: none;
  padding: 10px;
  border-bottom: 1px solid #909090;
  color: #909090;

}

/* -----------------------------------------------teachers */

.teachers_main_div{
  min-height: 600px;
  padding: 50px 100px ;
  display: flex;
}


.teachers_left_div>img{
width: 100%;
height: 100%;
object-fit: cover;
}


.teachers_right_div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  /* background-color: red; */
  padding: 60px 80px;
}

.teachers_right_div_link{
  text-decoration: none;
  color: #252020;
}

.teachers_right_div_link:hover{
  color: #258754;
}

/* ------------------------------------------------------------------------TeacherDetails */

.TeacherDetails_main_div{
  width: 100%;
  min-height: 80vh;
  /* background-color: #258754; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
}

.TeacherDetails_left_col{
  width: 35%;
  height: 80vh;
  /* background-color: black; */
  padding: 20px;
}

.TeacherDetails_left_img_col{
  width: 98%;
  height: 100%;
  /* background-color: red; */
}

.TeacherDetails_left_img_col>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.TeacherDetails_right_col{
  width: 65%;
  min-height: inherit;
  /* background-color: red; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

}

.TeacherDetails_right_col>span{
  color: #757575;
}

.TeacherDetails_right_col>h2{
  color: #252020;
}

.TeacherDetails_right_col_line{
  background-color: #252020;
  padding: 2px;
  width: 10%;
}

.TeacherDetails_right_col>p{
  color: #757575;
  text-align: start;
}


/* -----------------------------------------------------------------------scheduledpage_main_div */

.scheduledpage_calender{
  border-radius: 10px;
  border: 1px solid #258754;
}

.scheduledpage_calender_div{
  display: flex;
  flex-direction: column;
}

.scheduledpage_main_div{
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.scheduledpage_time_div{
  width: 30%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.scheduledpage_timeslot{
  width: 100%;
  background-color: white;
  padding: 10px; 
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #258754; 
}
.scheduledpage_timeslot>button{
  flex: 3;
  background-color: white;
  padding: 10px;
  border: 1px solid #28a745; 
}

.scheduledpage_display_section{
width: 30%;
border-radius: 10px;
border: 1px solid white;
padding: 10px;
}
.scheduledpage_display_section>p:nth-child(3){
  color: #258754;
}


/* ----------------------------------------------------------------------------------------------react-calender css */



.react-calendar { 
  width: 400px;
  max-width: 100%;
  background-color: white;
  color: black;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
 }

 .react-calendar__navigation button {
  color: black;
  min-width: 44px;
  background:none;
  font-size: 16px;
  margin-top: 8px;
 }

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  /* background: none; */
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #258754;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #258754;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #258754;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #258754;
  color: white;
}
.react-calendar__tile--now {
  /* background: #ffff76; */
  background-color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #258754;
  color: white;
}
.react-calendar__tile--hasActive {
  background: #258754;
  color: white;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #258754;
  color: white;
}
.react-calendar__tile--active {
  background: #258754;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #258754;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background: #258754;
  color: white;
}


/*--------------------------------------------------------------------------Popup  */

.popup{
  width: 500px;
  height: 500px;
  position:absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  background-color: white;
  border: 1px solid #258754;
  border-radius: 10px;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.popup_header{
  width: 100%;
  padding: 2px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_header:nth-child(1){
color: #258754;
}

.popup_form_div{
  width: 100%;
  height: 90%;
  padding: 10px;
  /* background-color: white;   */
}

.popup_form_div>form{
  width: 100%;
  height:100% ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: red; */
  padding: 10px;
}
.popup_form_div>form>label{
 color: #252020;
}


.popup_form_div>form>input{
  width: 100%;
  border-radius: 5px;
  padding: 4px;
  border: none;
  outline: none;
  color: #258754;
  border-bottom: 1px solid #258754;
}


.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* -----------------------------------------------------------------------------------------Dashboard */

.dashboard_main_div{
  min-height: 96.5vh;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_main_left_div{
  min-height: inherit;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  width: 18%;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}

.dashboard_main_right_div{
  min-height: inherit;
  width: 80%;
  padding: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}
 

.Dashboard_left_head_column{
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content:center;
  align-items: center;
  color: white;
  border-bottom: 2px solid rgba(229, 225, 225, 0.681);
  text-decoration: none;
  margin-bottom: 2px ;
  
}


.Dashboard_left_head_column>h5{
  font-weight: 700;
  font-size: 16px;  
 }

.Dashboard_left_column{
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  margin-top: 2px;
  text-decoration: none;
  color: white;
  background-color: #33906C;
}


.Dashboard_left_column_left_section{
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Dashboard_left_column_right_section{
  padding: 2px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Dashboard_left_column_right_section>h6{
  margin-top: 8px;
  font-weight: 600;
}

.Dashboard_left_column.active {
  color: black;
  background-color: white;
}

.Dashboard_left_column.active>.Dashboard_left_column_left_section {
  color: black;
}

.Dashboard_left_column.active>.Dashboard_left_column_right_section {
  color: black;
  
}
.Dashboard_left_column.active>.Dashboard_left_column_right_section>h6 {
  color: black;
  font-weight: 600;
  
}
.Dashboard_left_column.inactive {
  color: white;
  background-color: #33906C;
}


/* --------------------------------------------------------------------------------------------------------------------------------------------------------responsive */





@media only screen and (min-width: 398px) and (max-width: 1024px) {
  /* show element on medium-sized screens */

  .Home_text_div_col2>h3{
    width: 115%;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 8px;
  }
  .Home_text_div_col2>h1{
    font-size: 35px;
  }
  .Home_text_div_col2>button{
    width: 34%;
    font-size: 12px;
    padding: 18px;
    margin-top: 20px;
  }
  .Home_text_div_col2{
    left: 55%;
    width: 100%;
  }

  .Home_text_div_col3>h1{
    font-size: 27px;
  }
  .Home_text_div_col3>button{
    width: 34%;
    font-size: 12px;
    padding: 18px;
    margin-top: 20px;
  }
  .Home_text_div_col3>h3{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 9px;
    line-height: 11px;
  }

  .Home_text_div_col3{
    left: 55%;
    width: 100%;
  }

  .Home_text_div_col1>h3{
    font-size: 9px;
    line-height: 11px;
  }
  .Home_text_div_col1>button{
    width: 34%;
    font-size: 12px;
    padding: 18px;
    margin-top: 20px;
  }
  .Home_text_div_col1>h1{
    font-size: 28px;
  }
  .Home_text_div_col1{
    left: 55%;
    width: 100%;
  }


  /* --------------------------------------------nav*/

  .Navbar_main{
    padding: 0px 40px;
    width: 100%;
  }
  .Navbar_nav_div{
    display: none;
  }

/* -----------------------------------------------------------teacher */

  .teachers_main_div{
    min-height: 600px;
    padding: 50px 0px ;
    display: flex;
    flex-direction: column;
  }

  .teachers_right_div {
    padding: 60px 30px;
  }


  /*---------------------------------------------------------------courses */


  .Home_main_lower_div {
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
  }
  .Home_main_lower_box {
    border: none;
  }
  .Home_main_lower_box > p {
    color: #757575;
    font-size: 10px;
    width: 40%;
  }

  /* -------------------------------------------------------About */


  .About_main_column_div{
    flex-direction: column;
  }
  .About_main_column_left_div>h2{
    font-size: 16px;
  }
  .About_main_column_left_div_col_right>h3{
    font-size: 14px;
  }
  .About_main_column_left_div_col_right>p{
    font-size: 10px;
  }
  .About_main_div{
    padding: 0px 50px 0px 50px;
    align-items: flex-start;
  } 
  .About_main_column_left_div{
    width: initial;
  }
  .About_main_column_right_div{
    width: initial;
    padding: 0;
  }
  .About_main_column_right_form_div {
    width: 350px;
    height: 350px;
    padding: 50px 84px 50px 84px;
  }

  /* --------------------------------------------------our teacher */


  .ourteacher_main_lower_box{
    margin-top: 20px;
  }
  .ourteacher_main_page_div{
    justify-content: start;
    padding: 0px;
    min-height: 1770px;
  }
  .ourteacher_main_lower_div{
    flex-direction: column;

  }


  /* ---------------------------------------------------------------------review */


  .reviews_main_lower_div {
    flex-direction: column;
    min-height: 980px;
    margin-top: 20px;
  }
  .reviews_main_lower_box{
    margin-top: 20px;
  }

  /* ---------------------------------------------------------------------contact */

  .contact_main_page_div{
    flex-direction: column;
    min-height: 850px;
    padding: 50px 0px 50px 0px;
  }
  .contact_main_page_left_div{
    width: 100%;
    padding: 20px 40px;
  }
  .contact_main_page_left_div_input {
    margin-top: 20px;
  }
  .contact_main_page_left_div_submit_btn{
    padding: 8px;
    width: 35%;
    margin-top: 20px;
  }
  .contact_main_page_right_div {
    width: 95%;
  }

  /* --------------------------------------------------------footer */


  .footer_main_div{
    padding: 50px;
    height: 865px;
  }
  .footer_main_box{
    flex-direction: column;
  }
  .footer_main_box_col_3{
    width: 70%;
  }


  /* -------------------------------------------------------------scheduled responsive */


  .scheduledpage_main_div{
    flex-direction: column;
    padding: 100px 50px 100px 50px;
    margin-top:250px;
  }
  .scheduledpage_time_div{
    margin-top: 60px;
    width: 80%;
  }
  .scheduledpage_display_section{
    width: 80%;
    margin-top: 30px;
  }


  }